<div class="mrgnDiv">
    <ax-search-box [(text)]="filter" (onTextChanged)="onChange($event)" [debounceTime]="1000"></ax-search-box>
</div>
<div class="mrgnDiv">
    <table class="selecteditems" *ngIf="ww.mode=='multiple'">
        <tr *ngIf="vals.length">
            <td colspan="2">Selected</td>
        </tr>
        <tr *ngFor="let item of vals">
            <td *ngFor="let col of columnVisibles">{{item[col.fieldName]}}</td>
            <td class="tdDel">
                <a (click)="delete(item)">
                    <i class="fas fa-times"></i>
                </a>
            </td>
        </tr>
    </table>
    <table style="width: 100%;">
        <tr>
            <th *ngFor="let col of columnVisibles" class="divhead"
                [ngStyle]="{'width':(columnCount==1)?'100%':(100.0/columnCount)+'%'}">{{col.title}}</th>
        </tr>
        <cdk-virtual-scroll-viewport itemSize="30" class="fact-scroll-viewport">
            <tr *cdkVirtualFor="let item of dataSource" class="fact-item" (click)="selectItem(item)"
                [ngClass]="{'fact-item-single': ww.mode=='single' && showChecked(item),'fact-item-multiple':ww.mode=='multiple' && showChecked(item)}">
                <td [ngStyle]="{'width':(columnCount==1)?'100%':(100.0/columnCount)+'%'}"
                    *ngFor="let col of columnVisibles" class="fact-cell">
                    {{item[col.fieldName]}}</td>
            </tr>
        </cdk-virtual-scroll-viewport>
    </table>
    <div class="mrgnDiv">
        <ax-button (onClick)="submit()" *ngIf="ww.mode=='multiple'">
            Submit
        </ax-button>
    </div>
</div>